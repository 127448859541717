import { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';

// Body
export const BodyStyles = css`
  ${map(
    {
      mobile: '14px',
      tablet: '16px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 400;
  line-height: 1.55;
`;

export const SmallTextStyles = css`
  ${map(
    {
      mobile: '12px',
      tablet: '14px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 400;
  line-height: 1.4125;
`;

export const LargeTextStyles = css`
  ${map(
    {
      mobile: '14px',
      tablet: '16px',
      desktop: '18px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 400;
  line-height: 1.5;
`;

// Headings
export const HeroHeadingStyles = css`
  ${map(
    {
      mobile: '28px',
      tablet: '36px',
      desktop: '44px',
      xl: '48px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 450;
  line-height: 1.2;
  letter-spacing: -1px;
`;

export const H1Styles = css`
  ${map(
    {
      mobile: '26px',
      tablet: '32px',
      desktop: '36px',
      xl: '40px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: -0.85px;
`;

export const H2Styles = css`
  ${map(
    {
      mobile: '20px',
      tablet: '28px',
      desktop: '32px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 500;
  line-height: 1.25;
  letter-spacing: -0.3px;
`;

export const H3Styles = css`
  ${map(
    {
      mobile: '16px',
      tablet: '20px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 500;
  line-height: 1.25;
`;

export const H4Styles = css`
  ${map(
    {
      mobile: '14px',
      tablet: '16px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  font-weight: 600;
  line-height: 1.25;
`;
