import React from 'react';
import styled from 'styled-components';
import ParagraphGroup from './ParagraphGroup';
import ImageGroup from './ImageGroup';
import Image from './Image';
import Quote from './Quote';
import TakeAway from './TakeAway';
import Video from './Video';
import Model from './Model';

const Container = styled.div``;

const ArticleSerializer = ({ article_body }) => {
  return (
    <Container>
      {article_body.map(({ slice_type, primary, items }) => {
        switch (slice_type) {
          case 'paragraph_group':
            return <ParagraphGroup {...primary} />;
          case 'image_group':
            return <ImageGroup {...primary} items={items} />;
          case 'image':
            return <Image {...primary} />;
          case 'quote':
            return <Quote {...primary.text} />;
          case 'take_away':
            return <TakeAway {...primary.text} />;
          case 'video':
            return <Video {...primary} />;
          case '3d_model':
            return <Model {...primary} />;
          default:
            return null;
        }
      })}
    </Container>
  );
};

export default ArticleSerializer;
