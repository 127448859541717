import React from 'react';
import styled from 'styled-components';
import { pb } from 'styled-components-spacing';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { GridWrapperStyles, ImageGroupStyles } from '../Partials';
import { Text } from 'themes/components';

const Container = styled.div`
  ${GridWrapperStyles};
  ${ImageGroupStyles};
`;

const Wrapper = styled.div`
  ${(props) => (props.full_width ? `grid-column: 1/-1` : `grid-area: main`)};
  ${pb(0)};
`;

const Image = ({ caption, full_width, image }) => {
  const captionText = caption?.text;

  return (
    <Container {...{caption}}>
      <Wrapper {...{ full_width }}>
        <GatsbyImage image={getImage(image)} alt={image?.alt || captionText} />
      </Wrapper>
      { captionText && <Text variant="small">{captionText}</Text> }
    </Container>
  );
};

export default Image;
