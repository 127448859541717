import React from 'react';
import styled from 'styled-components';
import { mt, mb, px, py } from 'styled-components-spacing';

import { colors } from 'themes/components';
import { GridWrapperStyles } from '../Partials';
import TakeawayImage from '../../../../../static/takeaway.svg';

const Container = styled.div`
  ${GridWrapperStyles};
  color: ${colors.grey5};
  & > div {
    grid-area: main;
    font-size: 14px;
    line-height: 1.6;
    ${px(1)};
    ${py(1)};
    ${mt(2)};
    ${mb(1)};
    background: ${colors.grey60};
    border-radius: 4px;
    border-left: 4px solid ${colors.brand};
    position: relative;
    &:after {
      content: "";
      position: absolute;
      top: -20px;
      left: -20px;
      width: 32px;
      height: 32px;
      background: url(${TakeawayImage}) no-repeat center;
      background-size: 100%;
    }
  }
`;

const TakeAway = ({ text }) => {
  return <Container><div>{text}</div></Container>;
};

export default TakeAway;
