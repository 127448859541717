import React from 'react';
import styled from 'styled-components';

import { colors } from 'themes/components';

const TagWrapper = styled.span`
  display: inline-block;
  margin-right: 2px;
  border-radius: 6px;
  font-size: 14px;
  letter-spacing: -0.2px;
  &:not(:last-of-type):after {
    content: " ,";
    color: ${colors.grey30};
  }
`;

const Tag = ({ color, name, ...props }) => {
  if (!name) return null;
  return <TagWrapper style={{ color: color || colors.pink30 }} {...props}>{name}</TagWrapper>;
};

export default Tag;
