import { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { mt, mb } from 'styled-components-spacing';

import { colors } from 'themes/components';

// This is the base grid set up for a main and an aside grid areas
export const GridWrapperStyles = css`
  display: grid;
  ${map(
    {
      mobile: '20px',
      tablet: '40px'
    },
    (gap) => `grid-column-gap: ${gap};`
  )}
  ${map(
    {
      mobile: '1fr min(55ch, calc(100% - 40px)) 1fr',
      tablet:
        '1fr min(41ch, calc(100% - 80px)) min(23ch, calc(100% - 80px)) 1fr',
      desktop:
        '1fr min(55ch, calc(100% - 80px)) min(30ch, calc(100% - 80px)) 1fr'
    },
    (cols) => `grid-template-columns: ${cols};`
  )}
  ${map(
    {
      mobile: `'left aside right' 
               'left main right'`,
      tablet: `'left main aside right'`
    },
    (areas) => `grid-template-areas: ${areas};`
  )}
  max-width: 1280px;
  margin: 0 auto;
`;

export const ImageGroupStyles = css`
  ${mt(1)};
  & > p {
    grid-area: main;
    grid-row: 2;
    color: ${colors.grey30};
    ${mb(1)};
  }
`;

export const ArticleGridStyles = css`
  display: grid;
  ${map(
    {
      mobile: '1',
      tablet: '2',
      desktop: '3'
    },
    (width) => `grid-template-columns: repeat(${width}, 1fr);`
  )}
`;
