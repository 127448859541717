import React from 'react';
import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { mr, mt } from 'styled-components-spacing';

import { colors, Heading, Text } from 'themes/components';
import About from './AboutArtist';
import PodcastIcon from 'components/Icons/Podcast';
import { GridWrapperStyles } from '../Partials';

const Container = styled.div`
  ${GridWrapperStyles};
`;

const Lede = styled.div`
  grid-area: main;
  color: ${colors.grey10};
`;

const AboutWrapper = styled.div`
  color: ${colors.grey20};
  grid-area: aside;
  ${map(
    {
      mobile: '40px',
      tablet: '0px'
    },
    (marginBottom) => `margin-bottom: ${marginBottom};`
  )};
`;

const Podcast = styled.div`
  ${mt(3)}
`;

const PodcastHeading = styled(Heading)`
  color: ${colors.purple30};
  font-size: 18px;
  margin: 16px 0;
  font-weight: 600;
  display: flex;
`;

const PodcastIconContainer = styled.div`
  height: 24px;
  background: ${colors.purple30};
  border-radius: 8px;
  ${mr(0)};
  top: -1px;
  position: relative;
`;

const Intro = ({ intro, recording, artist, author }) => {
  return (
    <Container>
      {artist && (
        <AboutWrapper>
          <About {...{ artist }} />
        </AboutWrapper>
      )}
      <Lede>
        <Text variant="subhead">{intro?.text}</Text>
        {recording && (
          <Podcast>
            <PodcastHeading variant="h2">
              <PodcastIconContainer>
                <PodcastIcon style={{ fill: `${colors.grey90}` }} />
              </PodcastIconContainer>
              Listen to the Podcast
            </PodcastHeading>
            <iframe
              src={recording}
              width="100%"
              height="180"
              frameborder="0"
              seamless="true"
              style={{ width: '100%', height: '180px' }}
            ></iframe>
          </Podcast>
        )}
      </Lede>
    </Container>
  );
};

export default Intro;
