import { css } from 'styled-components';
import { colors } from 'themes/components';

export const CaretStyles = css`
  &:before,
  &:after {
    content: '';
    width: 1.5px;
    height: 8px;
    position: absolute;
    right: 4px;
    border-radius: 2px;
    background: ${colors.grey40};
    transition: background 0.2s ease;
  }
`;
