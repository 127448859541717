import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';

const ResponsiveHeaderStyles = css`
  ${map(
    {
      mobile: `${(props) => (props.featured ? '100%' : '100vw')}`,
      tablet: 'auto'
    },
    (width) => `width: ${width};`
  )}
  ${map(
    {
      mobile: '70vw',
      tablet: 'auto'
    },
    (height) => `height: ${height};`
  )}
`;

const HeaderImage = styled(GatsbyImage)`
  /* grid-column: 2/4; */
  /* grid-column: 1/4; */
  ${map(
    {
      mobile: '1/4',
      tablet: '1/5'
    },
    (gc) => `grid-column: ${gc};`
  )}
  border-radius: 8px;
  ${ResponsiveHeaderStyles};
`;

// This is used in the featured article component and the Showcase Header
const FeaturedArticleImage = ({ image, featured }) => (
  <HeaderImage {...{ image, featured }} alt={image?.alt || ''} />
);

export default FeaturedArticleImage;
