import React from 'react';
import styled from 'styled-components';
import { colors } from 'themes/components';
import Logo from 'components/Logo';

const LogoContainer = styled.div`
  margin: ${(props) => (props.header ? 'auto 24px auto 0px' : '')};
  z-index: 101;
  display: flex;
`;

const Tag = styled.span`
  color: ${colors.purple30};
  background: ${colors.purple70};
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  margin-left: 8px;
  position: relative;
  top: -4px;
`;

const Brand = (props) => (
  <LogoContainer {...props}>
    <Logo />
  </LogoContainer>
);

export default Brand;
