import React from 'react';
import styled from 'styled-components';
import { colors } from 'themes/components';

import Desktop from './Desktop';
import Mobile from './Mobile';
import Banner from './Banner';

const Container = styled.div`
  position: sticky;
  top: 0;
  padding-top: 0.1px;
  z-index: 100;
  background-color: ${colors.grey90};
`;

const Header = () => {
  return (
    <Container>
      {/* <Banner /> */}
      <Desktop />
      {/* pass hasBanner prop to Mobile when Banner is present */}
      <Mobile />
    </Container>
  );
};

export default Header;
