import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { useLocation } from '@reach/router'; // Update import

import { StaticImage } from 'gatsby-plugin-image';

import { colors } from 'themes/components';
import { CaretStyles } from './CaretStyles';

const SolutionLink = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 8px;
  border-radius: 24px;
  border: 1px solid transparent;
  transition: background 0.2s ease, border-color 0.2s ease;
  ${({ isActive }) =>
    isActive &&
    `
    background: hsla(231, 15%, 18%, 0.65);
  `}
  &:hover {
    background: hsla(231, 15%, 18%, 0.55);
    border-color: hsla(231, 15%, 18%, 1);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 20px;
  padding-right: 24px;
  position: relative;
  ${CaretStyles}
  &:before {
    transform: rotate(45deg);
    top: calc(50% - 2px);
  }
  &:after {
    transform: rotate(135deg);
    top: calc(50% - 7px);
  }
  ${SolutionLink}:hover & {
    &:before,
    &:after {
      background: ${colors.grey5};
    }
  }
`;

const Title = styled.h4`
  font-size: 16px;
  font-weight: 550;
  margin-bottom: 4px;
`;

const Desc = styled.p`
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.48;
  color: ${colors.grey5};
`;

const Solutions = () => {
  const location = useLocation(); // Get current location
  return (
    <>
      <SolutionLink
        to="/for-artists"
        isActive={
          location.pathname === '/for-artists' ||
          location.pathname === '/for-artists/'
        }
      >
        <StaticImage
          src="./icons/artists.png"
          alt="spray paint icon"
          layout="fixed"
          width={60}
          height={60}
        />
        <Content>
          <Title style={{ color: `${colors.pink30}` }}>For artists</Title>
          <Desc>
            Create and collaborate. Find your file and feedback— fast.
          </Desc>
        </Content>
      </SolutionLink>
      <SolutionLink
        to="/for-art-leads"
        isActive={
          location.pathname === '/for-art-leads' ||
          location.pathname === '/for-art-leads/'
        }
      >
        <StaticImage
          src="./icons/art-leads.png"
          alt="connected dots icon"
          layout="fixed"
          width={60}
          height={60}
        />
        <Content>
          <Title style={{ color: `${colors.green30}` }}>For art leads</Title>
          <Desc>Maintain your sanity. Keep a pulse on your team.</Desc>
        </Content>
      </SolutionLink>
      <SolutionLink
        to="/for-engineers"
        isActive={
          location.pathname === '/for-engineers' ||
          location.pathname === '/for-engineers/'
        }
      >
        <StaticImage
          src="./icons/engineers.png"
          alt="stylized terminal icon"
          layout="fixed"
          width={60}
          height={60}
        />
        <Content>
          <Title style={{ color: `${colors.purple30}` }}>For engineers</Title>
          <Desc>Build better pipelines. Make your teams happy.</Desc>
        </Content>
      </SolutionLink>
      <SolutionLink
        to="/for-producers"
        isActive={
          location.pathname === '/for-producers' ||
          location.pathname === '/for-producers/'
        }
      >
        <StaticImage
          src="./icons/producers.png"
          alt="stylized terminal icon"
          layout="fixed"
          width={60}
          height={60}
        />
        <Content>
          <Title style={{ color: `${colors.blue30}` }}>For producers</Title>
          <Desc>Get your information right from the source.</Desc>
        </Content>
      </SolutionLink>
    </>
  );
};

export default Solutions;
