import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const Discord = ({ fillColor, hoverFill, style }) => (
  <SvgIcon
    {...{ fillColor, hoverFill, style }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M18.4688 6.06542C17.279 5.51951 16.0032 5.11731 14.6693 4.88695C14.645 4.88251 14.6207 4.89362 14.6082 4.91584C14.4441 5.20767 14.2623 5.58839 14.1351 5.88764C12.7003 5.67284 11.2729 5.67284 9.86758 5.88764C9.74028 5.58174 9.55193 5.20767 9.38711 4.91584C9.3746 4.89436 9.35033 4.88325 9.32604 4.88695C7.99282 5.11657 6.71699 5.51877 5.52651 6.06542C5.5162 6.06986 5.50737 6.07728 5.5015 6.0869C3.08153 9.70229 2.41859 13.2288 2.74381 16.7116C2.74528 16.7287 2.75484 16.745 2.76809 16.7553C4.36472 17.9278 5.91133 18.6397 7.42922 19.1115C7.45351 19.1189 7.47925 19.11 7.49471 19.09C7.85377 18.5997 8.17383 18.0827 8.44826 17.539C8.46446 17.5071 8.449 17.4694 8.4159 17.4568C7.90821 17.2642 7.4248 17.0294 6.95979 16.7627C6.92301 16.7413 6.92006 16.6886 6.9539 16.6635C7.05176 16.5901 7.14964 16.5138 7.24308 16.4368C7.25998 16.4227 7.28354 16.4198 7.30341 16.4287C10.3583 17.8234 13.6656 17.8234 16.6845 16.4287C16.7044 16.419 16.728 16.422 16.7456 16.4361C16.8391 16.5131 16.9369 16.5901 17.0355 16.6635C17.0693 16.6886 17.0671 16.7413 17.0304 16.7627C16.5653 17.0346 16.0819 17.2642 15.5735 17.456C15.5404 17.4686 15.5257 17.5071 15.5419 17.539C15.8222 18.0819 16.1423 18.5989 16.4947 19.0893C16.5094 19.11 16.5359 19.1189 16.5602 19.1115C18.0854 18.6397 19.632 17.9278 21.2287 16.7553C21.2427 16.745 21.2515 16.7294 21.253 16.7124C21.6422 12.6859 20.601 9.18825 18.4931 6.08763C18.4879 6.07728 18.4791 6.06986 18.4688 6.06542ZM8.90446 14.591C7.98472 14.591 7.22688 13.7466 7.22688 12.7096C7.22688 11.6726 7.97003 10.8282 8.90446 10.8282C9.84624 10.8282 10.5967 11.68 10.582 12.7096C10.582 13.7466 9.83887 14.591 8.90446 14.591ZM15.107 14.591C14.1873 14.591 13.4295 13.7466 13.4295 12.7096C13.4295 11.6726 14.1726 10.8282 15.107 10.8282C16.0488 10.8282 16.7993 11.68 16.7846 12.7096C16.7846 13.7466 16.0488 14.591 15.107 14.591Z" />
  </SvgIcon>
);

export default Discord;
