import React from 'react';
import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { withArtDirection, getImage } from 'gatsby-plugin-image';

import { colors, Heading } from 'themes/components';
import { mt, mb, my, px } from 'styled-components-spacing';
import { GridWrapperStyles } from './Partials';
import Tags from './Tags';
import ArticlePersona from './ArticlePersona';
import FeaturedArticleImage from './FeaturedArticleImage';

const Container = styled.div`
  ${GridWrapperStyles};
  ${my({ mobile: 2, tablet: 4 })}
  ${px(2)}
`;

const ArticleDataContainer = styled.div`
  grid-column: 1/3;
  z-index: 1;
`;

const getArticleImageHeader = (title_image) => {
  return withArtDirection(getImage(title_image), [
    {
      media: '(max-width: 768px)',
      image: getImage(title_image?.thumbnails?.square)
    }
  ]);
};

const Header = ({
  title,
  subtitle,
  title_image,
  tags,
  article_date,
  artist,
  author,
  recording
}) => {
  const articleTitle = title?.text;
  return (
    <Container>
      <ArticleDataContainer>
        {tags.length > 0 && <Tags {...{ tags }} />}
        <Heading variant="h1" style={{ color: `${colors.grey5}` }}>
          {articleTitle}
        </Heading>
        {subtitle?.text && (
          <Heading variant="h3" style={{ color: `${colors.grey5}`, padding: '10px 0 0' }}>
            {subtitle.text}
          </Heading>
        )}
        <ArticlePersona {...{ author, artist, article_date }} />
      </ArticleDataContainer>
      {title_image?.url && (
        <FeaturedArticleImage
          image={getArticleImageHeader(title_image)}
          alt={title_image?.alt || articleTitle}
        />
      )}
    </Container>
  );
};

export default Header;
