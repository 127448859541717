import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const YouTube = ({ fillColor, hoverFill, style }) => (  
  <SvgIcon {...{fillColor, hoverFill, style}} width="24" height="24" viewBox="0 0 24 24">
<path d="M18.9804 4.16366C15.6768 3.945 8.31867 3.94589 5.01958 4.16366C1.44733 4.40011 1.02658 6.49252 1 12C1.02658 17.4977 1.44367 19.599 5.01958 19.8363C8.31958 20.0541 15.6768 20.055 18.9804 19.8363C22.5527 19.5999 22.9734 17.5075 23 12C22.9734 6.5023 22.5563 4.40099 18.9804 4.16366V4.16366ZM9.25 15.5555V8.44449L16.5833 11.9938L9.25 15.5555V15.5555Z"/>
  </SvgIcon>
);

export default YouTube;
