import React from 'react';
import styled from 'styled-components';
import { p, pt, py, mt, mb } from 'styled-components-spacing';

import { GridWrapperStyles } from '../Partials';
import { colors, Text } from 'themes/components';
import {
  BodyStyles,
  SmallTextStyles,
  H2Styles,
  H3Styles,
  H4Styles
} from 'themes/components/typographyStyles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Wrapper = styled.div`
  ${GridWrapperStyles};
`;

const ParagraphGroup = styled.div`
  grid-area: main;
  h2 {
    ${H2Styles};
    ${mt(2)};
    ${mb(0)};
    color: ${colors.grey5};
  }
  h3 {
    ${H3Styles};
    ${mt(2)};
    ${mb(0)};
    color: ${colors.grey5};
  }
  h4 {
    ${H4Styles};
    ${mt(2)};
    ${mb(0)};
    color: ${colors.grey5};
  }
  p {
    ${BodyStyles};
    ${mb(1)};
    color: ${colors.grey10};
    &:last-of-type {
      margin-bottom: 0;
    }
    a {
      color: ${colors.brand};
      transition: color 0.3s ease-in-out;
      &:hover {
        color: ${colors.yellow40};
      }
    }
  }
  pre {
    white-space: pre-wrap;
    overflow: auto;
    ${SmallTextStyles};
    ${p(1)};
    color: ${colors.grey10};
    background-color: ${colors.grey70};
    /* font-family: 'Fira Code', monospace; */
  }
  ${py(1)};
`;

const Caption = styled.div`
  grid-area: aside;
  color: ${colors.grey30};
  ${pt(1)};
`;

const Image = styled.div`
  grid-area: aside;
  ${pt(1)};
`;

const ParagraphGroupWithCaption = ({ text, caption, image, form_title, hubspot_form_id }) => {
  if (!text?.text && !caption?.text) return null;
  return (
    <Wrapper>
      {getImage(image) && (
        <Image>
          <GatsbyImage
            image={getImage(image)}
            alt={image?.alt || caption?.text || text?.text}
          />
        </Image>
      )}
      {caption?.text && (
        <Caption>
          <Text variant="small">
            <div dangerouslySetInnerHTML={{ __html: caption.html }} />
          </Text>
        </Caption>
      )}
      {text?.text && (
        <ParagraphGroup>
          <Text variant="small">
            <div dangerouslySetInnerHTML={{ __html: text.html }} />
          </Text>
        </ParagraphGroup>
      )}
    </Wrapper>
  );
};

export default ParagraphGroupWithCaption;
