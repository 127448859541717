import React from 'react';
import { colors } from 'themes/components';
import styled, { css } from 'styled-components';

export const ButtonStyles = css`
  display: inline-block;
  border-radius: 6px;
  padding: ${(props) =>
    props.small
      ? '6px 8px'
      : props.large
      ? '12px 16px 13px'
      : '6px 10px 7px 10px'};
  outline: none;
  border: none;
  background: ${(props) =>
    props.secondary
      ? `linear-gradient(180deg, #494C5A 0%, #494C5A 45%, #272935 55%, #272935 100%)`
      : props.bg
      ? props.bg
      : `linear-gradient(180deg, #7CFADB 0%, #7CFADB 43%, #30DFB4 53.5%, #30DFB4 100%)`};
  color: ${(props) => (props.secondary ? colors.grey5 : colors.grey90)};
  letter-spacing: ${(props) => (props.small ? '' : '-0.2px')};
  font: inherit;
  font-size: ${(props) => (props.small ? '12px' : '14px')};
  font-weight: 550;
  width: fit-content;
  cursor: pointer;
  transition: color 0.2s ease, background 0.2s ease-in-out, border 0.2s ease;
  text-decoration: none;
  position: relative;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: ${(props) =>
      props.secondary
        ? 'none'
        : '0px 0px 2px 0px rgba(0, 0, 0, 0.25), 0px 0px 15px 0px rgba(124, 250, 219, 0.40), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)'};
    border-radius: 6px;
    z-index: -1;
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }
  &:hover,
  &:focus {
    &:before {
      opacity: 1;
    }
  }
  &:disabled {
    color: ${colors.grey70};
    background: ${colors.teal50};
    cursor: not-allowed;
  }
`;

const ButtonContainer = styled.button`
  ${ButtonStyles}
`;

export const Button = ({ children, ...props }) => (
  <ButtonContainer {...props}>{children}</ButtonContainer>
);

export default Button;
