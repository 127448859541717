import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const Twitter = ({ fillColor, hoverFill, style }) => (
  <SvgIcon
    {...{ fillColor, hoverFill, style }}
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path d="M21.6667 5.89415C20.9725 6.21538 20.2328 6.42831 19.4617 6.53169C20.255 6.032 20.8605 5.24677 21.1452 4.30031C20.4055 4.76554 19.5888 5.09415 18.7185 5.27754C18.0162 4.48862 17.0152 4 15.9232 4C13.8045 4 12.0988 5.81415 12.0988 8.03815C12.0988 8.35815 12.1245 8.66585 12.1875 8.95877C9.006 8.79508 6.19083 7.18646 4.29967 4.736C3.9695 5.34031 3.77583 6.032 3.77583 6.77662C3.77583 8.17477 4.45833 9.41415 5.47567 10.1317C4.86083 10.1194 4.25767 9.93108 3.74667 9.63446C3.74667 9.64677 3.74667 9.66277 3.74667 9.67877C3.74667 11.6406 5.07317 13.2702 6.81267 13.6455C6.50117 13.7354 6.16167 13.7785 5.80933 13.7785C5.56433 13.7785 5.317 13.7637 5.08483 13.7095C5.58067 15.3083 6.98767 16.4837 8.66067 16.5218C7.35867 17.5963 5.7055 18.2437 3.91583 18.2437C3.602 18.2437 3.301 18.2289 3 18.1883C4.69517 19.3415 6.70417 20 8.87067 20C15.9127 20 19.7627 13.8462 19.7627 8.512C19.7627 8.33354 19.7568 8.16123 19.7487 7.99015C20.5082 7.42154 21.1463 6.71138 21.6667 5.89415Z" />
  </SvgIcon>
);

export default Twitter;
