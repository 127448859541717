const getArticleUrl = (id, showcase) => {
  const showcase_type = showcase?.uid || null;
  switch (showcase_type) {
    case null:
      return `/blog/${id}`;
    default:
      return `/blog/${showcase_type}/${id}`;
  }
};

export default getArticleUrl;
