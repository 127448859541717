import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'themes/components';
import { Link } from 'gatsby';
import { map } from 'styled-components-breakpoint';
import { py, pl, mx, mr } from 'styled-components-spacing';
import Brand from '../Brand';

import { CaretStyles } from './CaretStyles';
import Button from 'components/Button';
import Solutions from './Solutions';

const Wrapper = styled.div`
  ${map(
    {
      mobile: 'none',
      tablet: 'flex',
      desktop: 'flex'
    },
    (display) => `display: ${display};`
  )}
  user-select: none;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  ${pl(2)}
  ${py(1)}
  background-color: ${colors.grey90};
  box-sizing: border-box;
  position: relative;
  &:after {
    content: '';
    left: 0;
    right: 0;
    position: absolute;
    bottom: 0;
    height: 1px;
    background: linear-gradient(to right, #121216, #32343e, #121216);
  }
`;

const NavLinkStyles = css`
  cursor: pointer;
  font-size: 14px;
  line-height: 150%;
  font-weight: 550;
  letter-spacing: -0.2px;
  color: ${colors.grey5};
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  &:hover {
    color: ${colors.grey30};
  }
  &.active {
    color: ${colors.grey5};
    text-decoration: none;
  }
  &.secondary {
    ${map(
      {
        tablet: 'none',
        desktop: 'block'
      },
      (display) => `display: ${display};`
    )}
  }
`;

const HeaderDropdownLink = styled.div`
  ${NavLinkStyles}
  ${CaretStyles}
  position: relative;
  padding: 8px 24px 8px 0px;
  margin-right: 16px;
  ${(props) => (props.active ? `color: ${colors.grey5};` : '')}
  &:before,
  &:after {
    top: 40%;
  }
  &:before {
    transform: rotate(45deg);
    right: 9px;
  }
  &:after {
    transform: rotate(135deg);
    right: 14px;
  }
  &:hover {
    &:before,
    &:after {
      background: ${colors.grey5};
    }
  }
`;

const HeaderLink = styled(Link)`
  ${NavLinkStyles}
  position: relative;
  padding: 8px 24px 8px 0;
`;

const RightLinks = styled.div`
  display: flex;
  align-items: center;
  margin: auto 0px auto auto;
`;

const ActionLink = styled.a`
  ${NavLinkStyles}
  padding: 8px 24px 8px 0;
`;

const DropdownContainer = styled.div`
  cursor: default;
  position: absolute;
  width: 350px;
  padding: 12px;
  ${map(
    {
      mobile: 'left: 0; top: 0;',
      tablet: 'top: 48px; left: -16px'
    },
    (position) => `${position};`
  )}
  display: flex;
  flex-direction: column;
  background: ${colors.grey80};
  border: 1px solid ${colors.grey70};
  border-radius: 32px;
  box-shadow: 4px 4px 10px rgba(18, 18, 22, 0.8);
  z-index: 1;
`;

const SolutionsDropdown = ({ onMouseLeave }) => {
  return (
    <DropdownContainer onMouseLeave={onMouseLeave}>
      <Solutions />
    </DropdownContainer>
  );
};

const Header = () => {
  const [solutionDropdownOpen, setSolutionDropdownOpen] = useState(false);
  return (
    <Wrapper onMouseLeave={() => setSolutionDropdownOpen(false)}>
      <Brand header />
      <HeaderDropdownLink
        active={solutionDropdownOpen}
        onMouseEnter={() => setSolutionDropdownOpen(true)}
      >
        {solutionDropdownOpen && (
          <SolutionsDropdown
            onMouseLeave={() => setSolutionDropdownOpen(false)}
          />
        )}
        Solutions
      </HeaderDropdownLink>
      <HeaderLink
        to="/features"
        activeClassName="active"
        partiallyActive={true}
      >
        Features
      </HeaderLink>
      <HeaderLink to="/desktop" activeClassName="active" className="secondary">
        Desktop
      </HeaderLink>
      {/* <HeaderLink to="/pricing" activeClassName="active">
        Pricing
      </HeaderLink> */}
      <HeaderLink to="/blog" activeClassName="active" className="secondary">
        Blog
      </HeaderLink>
      <RightLinks>
        <Button as={Link} to="/get-a-demo">
          Get a demo
        </Button>
        {/* <ActionLink
          href="https://app.mudstack.com/signup"
          rel="nofollow"
          style={{ margin: '0 0 0 20px' }}
          className="secondary"
        >
          Sign up free
        </ActionLink> */}
        <ActionLink
          href="https://app.mudstack.com/login"
          rel="nofollow"
          style={{ margin: '0 0 0 20px' }}
          className="secondary"
        >
          Log in
        </ActionLink>
      </RightLinks>
    </Wrapper>
  );
};

export default Header;
