const linkResolver = (doc) => {
  // URL for a featured artist type
  if (doc.type === 'featured_artist') {
    return `/blog/artists/${doc.uid}/`;
  }

  // URL for a general type
  if (doc.type === 'general' || doc.type === 'article') {
    return `/blog/${doc.uid}/`;
  }

  // Backup for all other types
  return `/${doc.uid}/`;
};

module.exports = linkResolver;
