import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const Video = ({}) => {
  return <Container></Container>;
};

export default Video;
