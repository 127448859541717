import React from 'react';
import styled from 'styled-components';
import { mt, mb, my } from 'styled-components-spacing';

import { colors } from 'themes/components';
import Tag from './Tag';

const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  ${mb(0)}
  & > svg {
    position: relative;
    top: 1px;
    margin-right: 4px;
  }
`;

const Tags = ({ tags }) => {
  return (
    <TagsWrapper>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.90139 4H15.75C16.5784 4 17.25 4.67157 17.25 5.5V13C17.25 13.8284 16.5784 14.5 15.75 14.5H4.90139C4.39986 14.5 3.93151 14.2493 3.65331 13.8321L0.875962 9.66603C0.708013 9.4141 0.708013 9.0859 0.875962 8.83397L3.65331 4.66795C3.93151 4.25065 4.39986 4 4.90139 4ZM2.40139 9.25L4.90139 13H15.75V5.5H4.90139L2.40139 9.25ZM6 9.25C6 9.66421 5.66421 10 5.25 10C4.83579 10 4.5 9.66421 4.5 9.25C4.5 8.83579 4.83579 8.5 5.25 8.5C5.66421 8.5 6 8.83579 6 9.25Z"
          fill={`${colors.grey40}`}
        />
      </svg>
      {tags.map(({ color, name }) => (
        <Tag key={name} {...{ color, name }} />
      ))}
      {/* <Podcast /> */}
    </TagsWrapper>
  );
};

export default Tags;
