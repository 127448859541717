import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const Linkedin = ({ fillColor, hoverFill, style }) => (  
  <SvgIcon {...{fillColor, hoverFill, style}} width="24" height="24" viewBox="0 0 24 24">
    <path d="M18.8461 4H5.15393C4.5166 4 4 4.5166 4 5.15393V18.8461C4 19.4834 4.5166 20 5.15393 20H18.8461C19.4834 20 20 19.4834 20 18.8461V5.15393C20 4.5166 19.4834 4 18.8461 4V4ZM9.67529 16.0938H7.72693V10.2321H9.67529V16.0938ZM8.70117 9.43164H8.68848C8.03467 9.43164 7.61182 8.98157 7.61182 8.41907C7.61182 7.84387 8.04761 7.40625 8.71411 7.40625C9.38062 7.40625 9.79077 7.84387 9.80347 8.41907C9.80347 8.98157 9.38062 9.43164 8.70117 9.43164ZM16.7007 16.0938H14.7526V12.9579C14.7526 12.1698 14.4705 11.6323 13.7655 11.6323C13.2273 11.6323 12.9067 11.9949 12.7659 12.3448C12.7144 12.4701 12.7018 12.6451 12.7018 12.8203V16.0938H10.7535C10.7535 16.0938 10.7791 10.782 10.7535 10.2321H12.7018V11.062C12.9607 10.6626 13.424 10.0945 14.4576 10.0945C15.7395 10.0945 16.7007 10.9323 16.7007 12.7327V16.0938Z" />
  </SvgIcon>
);

export default Linkedin;
