import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const ArtStation = ({ fillColor, hoverFill, style }) => (  
  <SvgIcon {...{fillColor, hoverFill, style}} width="24" height="24" viewBox="0 0 24 24">
    <path d="M3 16.3394L4.52954 18.986C4.83888 19.5875 5.45757 20 6.16219 20H16.3534L14.2567 16.3394H3ZM21.1826 16.3566C21.1826 15.9957 21.0795 15.652 20.8904 15.3598L14.9098 4.97959C14.6004 4.39527 13.9989 4 13.2943 4H10.1321L19.3609 19.9828L20.8217 17.4565C21.0967 16.9753 21.1826 16.7691 21.1826 16.3566ZM12.7444 13.7272L8.61976 6.59506L4.49517 13.7272H12.7444Z" />
  </SvgIcon>
);

export default ArtStation;
