const colors = {
  text: '#B9B9C6',
  brand: '#7CFADB',
  primary: '#664EFA',
  background: '#21212C',
  heading: '#FFFFFF',
  grey5: '#E5E5E5',
  grey10: '#CFCFD8',
  grey20: '#B9B9C6',
  grey30: '#9897AA',
  grey40: '#575A6B',
  grey50: '#40424F',
  grey60: '#32343E',
  grey70: '#272935',
  grey80: '#1C1C22',
  grey90: '#121216',
  teal30: '#82C1B2',
  teal40: '#7CFADB',
  teal50: '#5CAC98',
  teal60: '#54DEBC50',
  teal70: '#0B3228',
  blue30: '#80DBFF',
  blue40: '#52BFFF',
  orange30: '#FFA05C',
  orange40: '#FF8229',
  red30: '#F17E7E',
  red40: '#EC5151',
  green30: '#B3D256',
  green40: '#54D472',
  yellow30: '#FBF08D',
  yellow40: '#E9D83F',
  purple10: '#E2CCFF',
  purple20: '#D1ADFF',
  purple30: '#C292FF',
  purple40: '#A863FF',
  purple70: '#35244C',
  pink30: '#FDB4FB',
  pink40: '#FC92F9',
  discord: '#5865F2',
  twitter: '#1D9BF0',
  youtube: '#FF0000',
  linkedin: '#0A66C2'
};

export default colors;
