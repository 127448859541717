import React from 'react';
import styled, { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { mt, ml, mb, my } from 'styled-components-spacing';
import { colors } from 'themes/components';
import Tags from './Tags';
import { SmallTextStyles, H4Styles } from 'themes/components/typographyStyles';

const Container = styled(Link)`
  text-decoration: none;
  padding: 12px;
  border-radius: 8px;
  background: transparent;
  transition: background 0.2s ease-in-out;
  color: inherit;
  align-self: start;
  ${(props) =>
    props.featured &&
    css`
      ${map(
        { mobile: '1', tablet: '1/3' },
        (gridColumn) => `grid-column: ${gridColumn};`
      )}
      ${map({ tablet: 'flex' }, (display) => `display: ${display};`)}
    /* This is the GatsbyImage wrapper */
    & > div:first-of-type {
        flex: 0 0 40%;
        ${map({ tablet: '24px' }, (mr) => `margin-right: ${mr};`)}
      }
    `}
  &:hover {
    background: ${colors.grey70};
  }
  &:visited {
    color: inherit;
  }
`;

const ArticleDataContainer = styled.div`
  & > h2 {
    ${H4Styles};
    color: ${colors.grey5};
    ${mt(1)};
    ${mb(0)};
  }
  ${(props) =>
    props.featured &&
    css`
      ${map({ tablet: '24px' }, (mb) => `margin-bottom: ${mb};`)}
      & > h2 {
        ${mt(2)};
      }
    `}
`;

const ArticleMeta = styled.span`
  color: ${colors.grey5};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
  ${mb(1)};
  & > span:first-of-type {
    font-weight: 500;
  }
`;

const Author = styled.div`
  display: flex;
  align-items: center;
  flex: 1 -1 auto;
  gap: 8px;
`;

const Date = styled.div`
  flex: 1 0 auto;
`;

const PreviewText = styled.p`
  ${SmallTextStyles};
  ${my(1)}
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: ${colors.grey20};
`;

const ArticlePersona = ({ author, article_date }) => {
  return (
    <ArticleMeta>
      <Author>
        <GatsbyImage
          style={{ borderRadius: '20px', width: '32px', height: '32px' }}
          image={getImage(author?.image)}
          alt={author?.alt || author.name}
        />
        <span>{author.name}</span>
      </Author>
      <Date>
        <span style={{ color: `${colors.grey30}` }}>
          &nbsp;• {article_date}
        </span>
      </Date>
    </ArticleMeta>
  );
};

const ArticleCard = ({
  url,
  title,
  title_image,
  tags,
  article_date,
  artist,
  author,
  featured,
  intro,
  recording
}) => {
  const articleTitle = title?.text;
  const ledeText = intro?.text;
  return (
    <Container to={url} {...{ featured }}>
      {title_image?.url && (
        <GatsbyImage
          style={{ borderRadius: '6px' }}
          image={getImage(title_image)}
          alt={title_image?.alt || articleTitle}
        />
      )}
      <ArticleDataContainer {...{ featured }}>
        <h2>{articleTitle}</h2>
        {featured && <PreviewText>{ledeText}</PreviewText>}
        <ArticlePersona {...{ author, artist, article_date }} />
        {tags.length > 0 && <Tags {...{ tags }} />}
      </ArticleDataContainer>
    </Container>
  );
};

export default ArticleCard;
