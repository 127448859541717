import React from 'react';
import styled from 'styled-components';
import HubspotForm from 'react-hubspot-form';
import { p, pt, py, mt, mb } from 'styled-components-spacing';
import { colors, Heading } from 'themes/components';
import { HubSpotFormStyles } from 'utils/HubSpotFormStyles';

const Wrapper = styled.div`
  background: ${colors.grey70};
  border: 1px solid ${colors.grey60};
  border-radius: 8px;
  ${p(1)};
  & > h3 {
    ${mb(2)};
  }
  ${HubSpotFormStyles}
`;

const ArticleHubspotForm = ({ formTitle, formId, portalId, ...props }) => {
  if (!formId) return null;
  
  return (
    <Wrapper {...props}>
      {formTitle && (
        <Heading variant="h3">{formTitle}</Heading>
      )}
      <HubspotForm
        portalId={portalId || "21257089"}
        formId={formId}
      />
    </Wrapper>
  );
};

export default ArticleHubspotForm;
