import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const Website = ({ fillColor, hoverFill, style }) => (  
  <SvgIcon {...{fillColor, hoverFill, style}} width="24" height="24" viewBox="0 0 24 24">
    <path id="icon" fillRule="evenodd" clipRule="evenodd" d="M20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12ZM18.0783 9.56692C17.414 7.90894 16.0911 6.58596 14.4331 5.92169C14.8221 6.67263 15.1272 7.6089 15.3329 8.6671C16.3911 8.87277 17.3274 9.17792 18.0783 9.56692ZM8.6671 8.6671C8.87277 7.6089 9.17792 6.67263 9.56692 5.92169C7.90894 6.58596 6.58596 7.90894 5.92169 9.56692C6.67263 9.17792 7.6089 8.87277 8.6671 8.6671ZM5.45455 12C5.45455 11.346 6.63904 10.6364 8.4469 10.2096C8.39203 10.786 8.36364 11.3855 8.36364 12C8.36364 12.6145 8.39203 13.214 8.4469 13.7904C6.63904 13.3636 5.45455 12.654 5.45455 12ZM5.92169 14.4331C6.67263 14.8221 7.6089 15.1272 8.6671 15.3329C8.87277 16.3911 9.17792 17.3274 9.56692 18.0783C7.90894 17.414 6.58596 16.0911 5.92169 14.4331ZM15.3329 15.3329C15.1272 16.3911 14.8221 17.3274 14.4331 18.0783C16.0911 17.414 17.414 16.0911 18.0783 14.4331C17.3274 14.8221 16.3911 15.1272 15.3329 15.3329ZM15.6364 12C15.6364 12.6145 15.608 13.214 15.5531 13.7904C17.361 13.3636 18.5455 12.654 18.5455 12C18.5455 11.346 17.361 10.6364 15.5531 10.2096C15.608 10.786 15.6364 11.3855 15.6364 12ZM9.81818 12C9.81818 11.2798 9.86335 10.5889 9.94372 9.94373C10.5889 9.86335 11.2798 9.81818 12 9.81818C12.7202 9.81818 13.4111 9.86335 14.0563 9.94373C14.1366 10.5889 14.1818 11.2798 14.1818 12C14.1818 12.7202 14.1367 13.4111 14.0563 14.0563C13.4111 14.1366 12.7202 14.1818 12 14.1818C11.2798 14.1818 10.5889 14.1366 9.94372 14.0563C9.86335 13.4111 9.81818 12.7202 9.81818 12ZM10.2096 15.5531C10.6364 17.361 11.346 18.5455 12 18.5455C12.654 18.5455 13.3636 17.361 13.7904 15.5531C13.214 15.608 12.6145 15.6364 12 15.6364C11.3855 15.6364 10.786 15.608 10.2096 15.5531ZM12 5.45455C11.346 5.45455 10.6364 6.63904 10.2096 8.4469C10.786 8.39204 11.3855 8.36364 12 8.36364C12.6145 8.36364 13.214 8.39203 13.7904 8.4469C13.3636 6.63904 12.654 5.45455 12 5.45455Z" />
  </SvgIcon>
);

export default Website;
