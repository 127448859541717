import React from 'react';
import { Script } from 'gatsby';

import { ShowcaseState } from './src/views/prismic/showcaseState.js';
import {
  PrismicPreviewProvider,
  componentResolverFromMap
} from 'gatsby-plugin-prismic-previews';

import linkResolver from './src/views/prismic/linkResolver.js';
import ArticleTemplate from './src/views/prismic/types/Article.js';

export const wrapRootElement = ({ element }) => {
  return (
    <PrismicPreviewProvider
      repositoryConfigs={[
        {
          linkResolver,
          repositoryName: 'mudstack',
          componentResolver: componentResolverFromMap({
            article: ArticleTemplate,
            featured_article: ArticleTemplate
          })
        }
      ]}
    >
      <ShowcaseState>{element}</ShowcaseState>
      <Script id="linkedin-data" dangerouslySetInnerHTML={{ __html: `_linkedin_partner_id = "3791436";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);` 
      }} />
      <Script id="linkedin-main-script" dangerouslySetInnerHTML={{ __html: `(function(l) {
            if (!l){
              window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
              window.lintrk.q=[]
            }
            var s = document.getElementsByTagName("script")[0];
            var b = document.createElement("script");
            b.type = "text/javascript";b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode.insertBefore(b, s);
          })(window.lintrk);` 
      }} />
    </PrismicPreviewProvider>
  );
};