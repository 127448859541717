import React from 'react';
import styled from 'styled-components';
import { colors, Heading, Text } from 'themes/components';
import { mt } from 'styled-components-spacing';

import SocialLinks from '../SocialLinks';
import WebsiteIcon from 'components/Icons/Website';

const ProfileSite = styled.div`
  display: flex;
  align-items: center;
  ${mt(0)};
  & > svg {
    position: relative;
    top: 1px;
    margin-left: -4px;
    margin-right: 4px;
  }
  & > a {
    color: ${colors.brand};
    text-decoration: none;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${colors.grey5};
    }
  }
`;

const AboutArtist = ({ artist }) => {
  return (
    <>
      {/* to do: when we have an artist profile, add in image here and move this component away from lede paragraph */}
      <Heading
        variant="h4"
        style={{ color: `${colors.grey5}`, marginBottom: '8px' }}
      >
        About the Artist
      </Heading>
      <Text variant="small">{artist.description}</Text>
      {artist.website && (
        <ProfileSite>
          <WebsiteIcon fillColor={`${colors.grey40}`} />
          <Text variant="small" as="a" href={artist.website}>
            {artist.website}
          </Text>
        </ProfileSite>
      )}
      <SocialLinks {...artist} />
    </>
  );
};

export default AboutArtist;
