import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { px, py, mt, mr, mx } from 'styled-components-spacing';
import { colors } from 'themes/components';

const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  position: sticky;
  left: 0;
  right: 0;
  z-index: 201;
`;

const Container = styled(Link)`
  ${mx(2)}
  color: ${colors.purple20};
  font-size: 14px;
  font-weight: 500;
  ${px(1)};
  ${py(0)};
  background: ${colors.purple40}33;
  border: 1px solid ${colors.purple40}50;
  border-radius: 8px;
  ${mt(0)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${colors.purple20};
    border-color: ${colors.purple20}50;
  }
`;

const Content = styled.span`
  flex-grow: 1;
  ${mr(1)}
`;

const Cta = styled.span`
  white-space: nowrap;
  a {
    text-decoration: none;
    color: inherit;
    transition: color 0.2s ease-in-out;
    &:hover {
      color: ${colors.purple10};
    }
  }
`;

const Banner = () => (
  <Wrapper>
    <Container to="/desktop">
      <Content>mudstack v1 is now live!</Content>
      <Cta>
        <Link>Sync and push your changes &rarr;</Link>
      </Cta>
    </Container>
  </Wrapper>
);

export default Banner;
