import React from 'react';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';

// import GlobalBanner from './GlobalBanner';
import Header from './Header';
import Footer from './Footer';
import GlobalStyles from '../themes/globalStyle';
import theme from '../themes';

const Main = styled.main`
  background: ${(props) => props.bg};
  max-width: 1400px;
  margin: 0 auto;
`;

const Layout = ({ bg, seo, children, noHeader, noFooter }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    {/* <GlobalBanner /> */}
    {!noHeader && <Header />}
    <Main {...{ bg }}>{children}</Main>
    {!noFooter && <Footer />}
  </ThemeProvider>
);

export default Layout;
