const spacing = {
  0: '8px',
  1: '16px',
  2: '24px',
  3: '32px',
  4: '40px',
  5: '48px',
  6: '56px',
  7: '64px',
  8: '72px',
  9: '80px',
  10: '88px',
  11: '96px',
  12: '104px'
};

export default spacing;
