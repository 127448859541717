import React from 'react';
import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';

import {
  BodyStyles,
  SmallTextStyles,
  LargeTextStyles
} from './typographyStyles';

const Bodytext = styled.p`
  ${BodyStyles};
`;

const getSubheadFont = (props) =>
  map(
    {
      mobile: props.hero ? '18px' : '16px',
      tablet: props.hero ? '24px' : '18px'
    },
    (fontSize) => `font-size: ${fontSize};`
  );

const Subhead = styled.p`
  ${getSubheadFont};
  font-weight: ${(props) => (props.hero ? '400' : '500')};
  line-height: 1.5;
  letter-spacing: -0.2px;
`;

const Tagline = styled.p`
  ${map(
    {
      mobile: '12px',
      tablet: '14px'
    },
    (fontSize) => `font-size: ${fontSize};`
  )};
  ${map(
    {
      mobile: '2.4px',
      tablet: '3px'
    },
    (ls) => `letter-spacing: ${ls};`
  )};
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
`;

const SmallText = styled.p`
  ${SmallTextStyles};
`;

const LargeText = styled.p`
  ${LargeTextStyles};
`;

const Text = ({ className, variant, style, children, ...props }) => {
  let Component = Bodytext;
  switch (variant) {
    case 'subhead':
      Component = Subhead;
      break;
    case 'tagline':
      Component = Tagline;
      break;
    case 'small':
      Component = SmallText;
      break;
    case 'large':
      Component = LargeText;
      break;
  }
  return (
    <Component {...{ style, className }} {...props}>
      {children}
    </Component>
  );
};

export default Text;
