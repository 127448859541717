import React from 'react';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import Layout from 'components/Layout';
import { colors } from 'themes/components';
import styled from 'styled-components';
import SEO from 'components/SEO';
import ArticleSerializer from '../components/ArticleSerializer';
import Header from '../components/Header';
import Intro from '../components/Intro';
import Footer from '../components/Footer';
import linkResolver from '../linkResolver';
import { getAuthor, getArtist, getArticleTags } from './utils';
import ArticleHubspotForm from '../components/ArticleHubspotForm';
import { GridWrapperStyles } from '../components/Partials';

const FormContainer = styled.div`
  ${GridWrapperStyles};
`;
const Form = styled(ArticleHubspotForm)`
  grid-area: main;
`;

export const query = graphql`
  query ArticleQuery($uid: String!) {
    prismicArticle(uid: { eq: $uid }) {
      _previewable
      tags
      uid
      data {
        article_body {
          ... on PrismicArticleDataArticleBodyParagraphGroup {
            primary {
              text {
                html
                richText
                text
              }
              caption {
                html
                richText
                text
              }
              image {
                url
                alt
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            slice_type
          }
          ... on PrismicArticleDataArticleBodyQuote {
            primary {
              text {
                html
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicArticleDataArticleBodyTakeAway {
            primary {
              text {
                html
                richText
                text
              }
            }
            slice_type
          }
          ... on PrismicArticleDataArticleBodyVideo {
            primary {
              embed {
                id
                # embed_url
                # url
                # html
              }
            }
            slice_type
          }
          ... on PrismicArticleDataArticleBody3dModel {
            primary {
              url {
                url
                target
              }
            }
            slice_type
          }
          ... on PrismicArticleDataArticleBodyImageGroup {
            slice_type
            primary {
              caption {
                html
                richText
                text
              }
            }
            items {
              images {
                alt
                url
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          ... on PrismicArticleDataArticleBodyImage {
            slice_type
            primary {
              caption {
                html
                richText
                text
              }
              full_width
              image {
                alt
                url
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  # formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        article_date(formatString: "MMMM Do YYYY")
        showcase_type {
          uid
          document {
            ... on PrismicShowcaseType {
              data {
                color
                name {
                  text
                }
              }
            }
          }
        }
        author {
          document {
            ... on PrismicAuthor {
              data {
                artstation {
                  url
                  target
                }
                description {
                  text
                  richText
                  html
                }
                image {
                  url
                  alt
                  gatsbyImageData(
                    layout: FIXED
                    aspectRatio: 1
                    placeholder: BLURRED
                    # formats: [AUTO, WEBP, AVIF]
                  )
                }
                instagram {
                  url
                  target
                }
                website {
                  url
                  target
                }
                linkedin {
                  url
                  target
                }
                location {
                  text
                  richText
                  html
                }
                name {
                  html
                  richText
                  text
                }
                twitter {
                  url
                  target
                }
              }
            }
          }
        }
        artist_artstation {
          url
          target
        }
        artist_description {
          text
          richText
          html
        }
        artist_image {
          url
          alt
          gatsbyImageData(
            layout: FIXED
            aspectRatio: 1
            placeholder: BLURRED
            # formats: [AUTO, WEBP, AVIF]
          )
        }
        artist_instagram {
          url
          target
        }
        artist_website {
          url
          target
        }
        artist_linkedin {
          url
          target
        }
        artist_location {
          text
          richText
          html
        }
        artist_name {
          html
          richText
          text
        }
        artist_twitter {
          url
          target
        }
        intro {
          text
          richText
          html
        }
        # recording {
        #   url
        #   embed_url
        #   html
        # }
        title {
          text
          richText
          html
        }
        subtitle {
          text
          richText
          html
        }
        title_image {
          url
          alt
          gatsbyImageData(
            layout: FULL_WIDTH
            aspectRatio: 2.72727272727
            placeholder: BLURRED
            # formats: [AUTO, WEBP, AVIF]
          )
          thumbnails {
            square {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                # formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        form_title {
          text
          richText
          html
        }
        hubspot_form_id {
          text
        }
        seo_body {
          ... on PrismicArticleDataSeoBodyGeneral {
            primary {
              description {
                text
                richText
                html
              }
              image {
                url
                alt
              }
              title {
                text
                richText
                html
              }
            }
            slice_type
          }
          ... on PrismicArticleDataSeoBodyTwitter {
            id
            primary {
              title {
                html
                richText
                text
              }
              image {
                alt
                url
              }
              description {
                html
                richText
                text
              }
              twitter_handle
            }
            slice_type
          }
        }
      }
    }
  }
`;

const ArticleArticle = ({ data }) => {
  if (!data) return null;
  const {
    prismicArticle: { uid, tags, data: article_metadata }
  } = data;
  const {
    article_body,
    intro,
    author: author_meta,
    showcase_type,
    seo_body,
    recording,
    form_title,
    hubspot_form_id,
  } = article_metadata;
  const author = getAuthor(author_meta);
  const artist = getArtist(article_metadata);
  const articleTags = getArticleTags(tags, showcase_type);
  // layout is the page layout.
  // we pass in a prop seo using a helper utility that extracts seo metadata from article data
  return (
    <Layout bg={`${colors.grey90}`}>
      {/* header is the article header, see the component for what it looks for*/}
      <Header
        {...article_metadata}
        author={author}
        artist={artist}
        tags={articleTags}
      />
      {/* intro takes the actual article intro, author metadata, artist metadata (for artist articles), and it also takes recording data */}
      <Intro
        intro={intro}
        recording={recording?.embed_url}
        author={author}
        artist={artist}
      />
      {/* the article serializer captures the actual body of the article and converts it into the proper components*/}
      <ArticleSerializer article_body={article_body} />
      <FormContainer>
        <Form formTitle={form_title?.text} formId={hubspot_form_id?.text} />
      </FormContainer>
      <Footer {...{ author }} />
    </Layout>
  );
};

export const Head = ({ data }) => {
  const seoBody = data?.prismicArticle?.data?.seo_body;
  if (!seoBody) return null;

  const { primary: generalSEO } = seoBody.find((slice) => slice.slice_type === 'general') || {};
  const { primary: twitterSEO } = seoBody.find((slice) => slice.slice_type === 'twitter') || {};
  
  let twitter;
  if (twitterSEO) {
    twitter = {
      title: twitterSEO.title?.text,
      description: twitterSEO.description?.text,
      image: twitterSEO.image?.url,
      handle: twitterSEO.twitter_handle
    };
  }
  return <SEO title={generalSEO?.title?.text} description={generalSEO?.description?.text} image={generalSEO?.image?.url} twitter={twitter}/>;
};

export default withPrismicPreview(ArticleArticle, [
  {
    repositoryName: 'mudstack',
    linkResolver
  }
]);
