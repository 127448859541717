const getArtist = (data) => {
  const {
    artist_name,
    artist_location,
    artist_description,
    artist_image,
    artist_twitter,
    artist_artstation,
    artist_instagram,
    artist_linkedin,
    artist_website
  } = data;
  const artist = {
    name: artist_name?.text,
    description: artist_description?.text,
    image: artist_image,
    location: artist_location?.text,
    website: artist_website?.url,
    instagram: artist_instagram?.url,
    twitter: artist_twitter?.url,
    artstation: artist_artstation?.url,
    linkedin: artist_linkedin?.url
  };
  if (!artist.name && !artist.image.url && !artist.location) return null;
  return artist;
};
export default getArtist;
