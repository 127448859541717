import React from 'react';
import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';

const Wrapper = styled.div`
  ${map(
    {
      mobile: '60px',
      tablet: '80px',
      desktop: '120px'
    },
    (pt) => `padding-top: ${pt};`
  )}
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    transform: translateZ(0);
    width: 60%;
    left: 20%;
    top: -110px;
    height: 220px;
    border-radius: 1000px;
    opacity: 0.25;
    filter: blur(160px);
    background: ${(props) => `linear-gradient(
      307deg,
      ${props.glow1} 22%,
      ${props.glow2} 41%,
      ${props.glow3} 90%
    )`};
  }
  &:after {
    left: 0;
    right: 0;
    height: 1px;
    background: ${(props) => `linear-gradient(
      to right,
      #121216,
      ${props.borderColor},
      #121216
    )`};
  }
`;

const GlowBorder = (props) => <Wrapper {...props} />;

export default GlowBorder;
