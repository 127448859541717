import { colors } from 'themes/components';

const getTagColor = (tag) => {
  switch (tag) {
    case 'Best Practices':
    default:
      return colors.pink30;
  }
};

const getArticleTags = (tags, showcase) => {
  const showcase_name = showcase?.document?.data?.name?.text || '';
  const showcase_color = showcase?.document?.data?.color || colors.pink30;
  const tagList = tags.map((tag) => ({ name: tag, color: getTagColor(tag) }));
  if (showcase_name) {
    tagList.push({ name: showcase_name, color: showcase_color });
  }
  return tagList;
};

export default getArticleTags;
