import React from 'react';
import styled from 'styled-components';

import { colors } from 'themes/components';
import { mt } from 'styled-components-spacing';

import TwitterIcon from 'components/Icons/Twitter';
import ArtstationIcon from 'components/Icons/Artstation';
import LinkedinIcon from 'components/Icons/Linkedin';
import InstagramIcon from 'components/Icons/Instagram';

const Wrapper = styled.div`
  ${mt(0)};
  display: flex;
  align-items: center;
`;

const SocialLinks = ({ linkedin, twitter, artstation, instagram }) => (
  <Wrapper>
    <div style={{ marginRight: '16px' }}>
      {twitter && (
        <a href={twitter}>
          <TwitterIcon
            fillColor={`${colors.grey30}`}
            hoverFill={`${colors.brand}`}
            style={{ margin: '4px 4px 0 0' }}
          />
        </a>
      )}
      {artstation && (
        <a href={artstation}>
          <ArtstationIcon
            fillColor={`${colors.grey30}`}
            hoverFill={`${colors.brand}`}
            style={{ margin: '4px 4px 0 0' }}
          />
        </a>
      )}
      {instagram && (
        <a href={instagram}>
          <InstagramIcon
            fillColor={`${colors.grey30}`}
            hoverFill={`${colors.brand}`}
            style={{ margin: '4px 4px 0 0' }}
          />
        </a>
      )}
      {linkedin && (
        <a href={linkedin}>
          <LinkedinIcon
            fillColor={`${colors.grey30}`}
            hoverFill={`${colors.brand}`}
            style={{ margin: '4px 4px 0 0' }}
          />
        </a>
      )}
    </div>
  </Wrapper>
);

export default SocialLinks;
