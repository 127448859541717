import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { StaticQuery, graphql } from 'gatsby';
import { mt, pt } from 'styled-components-spacing';
import _ from 'lodash';

import { colors, Heading, Text } from 'themes/components';
import { ArticleGridStyles, GridWrapperStyles } from './Partials';
import SocialLinks from './SocialLinks';
import ArticleCard from './ArticleCard';
import {
  getArticleUrl,
  getAuthor,
  getArtist,
  getArticleTags
} from '../types/utils';
import { H2Styles } from 'themes/components/typographyStyles';

const query = graphql`
  query ArticleFooterQuery {
    allPrismicArticle(limit: 3, sort: { data: { article_date: DESC } }) {
      nodes {
        type
        tags
        uid
        data {
          article_date(formatString: "MMMM Do YYYY")
          showcase_type {
            uid
            document {
              ... on PrismicShowcaseType {
                data {
                  color
                  name {
                    text
                  }
                }
              }
            }
          }
          author {
            document {
              ... on PrismicAuthor {
                data {
                  artstation {
                    url
                    target
                  }
                  description {
                    text
                    richText
                    html
                  }
                  image {
                    url
                    alt
                    gatsbyImageData(
                      layout: FIXED
                      aspectRatio: 1
                      placeholder: BLURRED
                    )
                  }
                  instagram {
                    url
                    target
                  }
                  website {
                    url
                    target
                  }
                  linkedin {
                    url
                    target
                  }
                  location {
                    text
                    richText
                    html
                  }
                  name {
                    html
                    richText
                    text
                  }
                  twitter {
                    url
                    target
                  }
                }
              }
            }
          }
          artist_artstation {
            url
            target
          }
          artist_description {
            text
            richText
            html
          }
          artist_image {
            url
            alt
            gatsbyImageData(layout: FIXED, aspectRatio: 1, placeholder: BLURRED)
          }
          artist_instagram {
            url
            target
          }
          artist_website {
            url
            target
          }
          artist_linkedin {
            url
            target
          }
          artist_location {
            text
            richText
            html
          }
          artist_name {
            html
            richText
            text
          }
          artist_twitter {
            url
            target
          }
          intro {
            text
            richText
            html
          }
          title {
            text
            richText
            html
          }
          subtitle {
            text
            richText
            html
          }
          title_image {
            url
            alt
            gatsbyImageData(
              layout: FULL_WIDTH
              aspectRatio: 2
              placeholder: BLURRED
            )
            thumbnails {
              square {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  ${GridWrapperStyles};
  ${mt(2)};
  & > h2 {
    ${H2Styles};
    color: ${colors.grey5};
    grid-column: 2;
    ${mt(7)};
  }
`;

const AuthorContainer = styled.div`
  grid-area: main;
  ${pt(2)}
  border-top: 1px solid ${colors.grey60};
`;

const Header = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const AuthorLinks = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const ArticleContainer = styled.div`
  grid-column: 2/-2;
  ${ArticleGridStyles};
  padding: 16px 0 48px 0;
  margin: 0 -12px;
`;

const Footer = ({ author }) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => {
        const { nodes: articles } = data?.allPrismicArticle;
        return (
          <>
            {author && (
              <Wrapper>
                <AuthorContainer>
                  <Header>
                    <GatsbyImage
                      style={{
                        borderRadius: '20px',
                        width: '40px',
                        height: '40px'
                      }}
                      image={getImage(author.image)}
                      alt={author.image?.alt || author.name}
                    />
                    <Text
                      variant="small"
                      style={{ color: `${colors.grey5}`, fontWeight: '700' }}
                    >
                      About {author.name} &rarr;
                    </Text>
                  </Header>
                  <div>
                    <Text
                      variant="small"
                      style={{ color: `${colors.grey20}`, marginTop: '8px' }}
                    >
                      {author.description}
                    </Text>
                    <AuthorLinks>
                      <Text
                        variant="small"
                        style={{
                          color: `${colors.grey5}`,
                          marginTop: '8px',
                          fontWeight: '600'
                        }}
                      >
                        Connect with {author.name.split(' ')[0]}
                      </Text>
                      <SocialLinks {...author} />
                    </AuthorLinks>
                  </div>
                </AuthorContainer>
              </Wrapper>
            )}
            <Wrapper>
              <h2>More from our blog</h2>
              <ArticleContainer>
                {articles.map((article) => {
                  const { type, uid, tags, data: article_metadata } = article;
                  const url = getArticleUrl(
                    uid,
                    article_metadata?.showcase_type
                  );
                  const articleAuthor = getAuthor(article_metadata?.author);
                  const articleArtist = getArtist(article_metadata);
                  const articleTags = getArticleTags(
                    tags,
                    article_metadata?.showcase_type
                  );
                  return (
                    <ArticleCard
                      {...{
                        ...article_metadata,
                        author: articleAuthor,
                        artist: articleArtist,
                        tags: articleTags,
                        uid,
                        type,
                        url
                      }}
                    />
                  );
                })}
              </ArticleContainer>
            </Wrapper>
          </>
        );
      }}
    />
  );
};

export default Footer;
