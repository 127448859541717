import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const Podcast = ({ fillColor, hoverFill, style }) => (  
  <SvgIcon {...{fillColor, hoverFill, style}} width="24" height="24" viewBox="0 0 24 24">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 4C13.6569 4 15 5.34315 15 7V12.25C15 13.9069 13.6569 15.25 12 15.25C10.3432 15.25 9 13.9069 9 12.25V7C9 5.34315 10.3432 4 12 4ZM12.75 18.2036V19H15V20.5H9V19H11.25V18.2036C8.29027 17.8345 6 15.3097 6 12.25V10.75H7.5V12.25C7.5 14.7353 9.51472 16.75 12 16.75C14.4853 16.75 16.5 14.7353 16.5 12.25V10.75H18V12.25C18 15.3097 15.7097 17.8345 12.75 18.2036ZM10.5 7C10.5 6.17157 11.1716 5.5 12 5.5C12.8284 5.5 13.5 6.17157 13.5 7V12.25C13.5 13.0784 12.8284 13.75 12 13.75C11.1716 13.75 10.5 13.0784 10.5 12.25V7Z" />
  </SvgIcon>
);

export default Podcast;
