import { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { colors } from 'themes/components';
import { p, px, pt, mr, mb, mt, mx } from 'styled-components-spacing';

const inputStyles = css`
  width: 100%;
  max-width: 100% !important;
  background: ${colors.grey90};
  border: 1px solid ${colors.grey60};
  height: 36px;
  border-radius: 4px;
  color: inherit;
  font-size: 15px;
  width: 100% !important;
  ${mb(0)}
  ${px(0)}
`;

export const HubSpotFormStyles = css`
  /* Hubspot form embed styling starts */
  color: ${colors.grey5};
  .hs-email {
    label {
      font-size: 14px;
      font-weight: 500;
    }
    .input {
      margin-top: 8px;
    }
    input {
      ${inputStyles}
    }
  }
  .hs-fieldtype-textarea {
    width: 100%;
  }
  textarea {
    ${inputStyles}
    height: auto;
    margin-top: 4px;
    padding: 8px;
  }
  .hs-form-required {
    color: ${colors.purple30};
    margin-left: 2px;
  }
  fieldset {
    display: flex;
    ${map(
      {
        mobile: 'column',
        tablet: 'row'
      },
      (direction) => `flex-direction: ${direction};`
    )}
    justify-content: space-between;
    margin-bottom: 12px !important;
    ${map(
      {
        mobile: '0',
        tablet: '12px'
      },
      (mb) => `margin-bottom: ${mb} !important;`
    )}
    max-width: 100% !important;
    label {
      font-size: 14px;
      font-weight: 500;
    }
    &.form-columns-2 {
      .hs-form-field {
        ${map(
          {
            mobile: '100%',
            tablet: '48%'
          },
          (width) => `width: ${width} !important;`
        )}
      }
      .hs-form-required {
        color: ${colors.purple30};
        margin-left: 2px;
      }
      /* input container */
      .input {
        margin: 4px 0 0 !important;
        input,
        select {
          ${inputStyles}
        }
        /* dropdown */
        select {
          padding: 4px 8px;
          appearance: none;
        }
      }
      /* error message */
      ul {
        padding: 0 0 0 4px;
        margin-top: 4px;
        list-style: none;
        font-size: 12px;
        color: ${colors.red30};
      }
    }
    &.form-columns-1 {
      ul {
        padding: 0;
        list-style: none;
        font-size: 14px;
        input {
          margin: 0 8px 0 0;
        }
      }
      .hs-input:not([type='checkbox']) {
        width: 100% !important;
      }
    }
  }
  .hs-error-msgs,
  label.hs-main-font-element {
    padding: 0;
    list-style: none;
    color: ${colors.red30};
    font-size: 14px;
    font-weight: 500;
  }
  .hs-submit {
    input {
      appearance: none;
      background: ${colors.teal40};
      color: ${colors.grey90};
      min-height: 40px;
      border: 0;
      border-radius: 8px;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      line-height: 150%;
      padding: 8px 12px;
      width: 100%;
    }
  }
  /* Hubspot form embed styling ends */
`;
