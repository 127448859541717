import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { p, pr, ml, my, mb } from 'styled-components-spacing';

import { colors } from 'themes/components';

const PersonaData = styled.div`
  background: ${colors.grey90};
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  font-size: 14px;
  ${my({ mobile: 1, tablet: 3 })};
`;

const PersonaLocationIcon = styled.svg`
  ${ml(1)};
  fill: ${colors.grey30};
  position: relative;
  top: 1px;
`;

const PersonaName = styled.span`
  color: ${colors.grey5};
  ${ml(1)};
`;

const PersonaMeta = styled.span`
  color: ${colors.grey30};
  ${ml(0)};
`;

const ArticlePersona = ({ artist, author, article_date }) => {
  const target = artist || author;
  return (
    <PersonaData>
      <GatsbyImage
        style={{ borderRadius: '20px', width: '32px', height: '32px' }}
        image={getImage(target?.image)}
        alt={target?.image?.alt || target?.name}
      />
      <PersonaName>{target.name}</PersonaName>
      <span style={{ color: `${colors.grey30}` }}>&nbsp;• {article_date}</span>
    </PersonaData>
  );
};

export default ArticlePersona;
