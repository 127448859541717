import React from 'react';
import styled from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { mt, py, pl } from 'styled-components-spacing';

import { colors } from 'themes/components';
import { GridWrapperStyles } from '../Partials';
import QuoteImage from '../../../../../static/quote.svg';

const Container = styled.div`
  ${GridWrapperStyles};
  color: ${colors.grey5};
  ${mt(2)};
  & > div {
    grid-area: main;
    ${map(
      {
        mobile: '20px',
        tablet: '24px',
        desktop: '28px',
      },
      (fontSize) => `font-size: ${fontSize};`
    )};
    font-weight: 300;
    line-height: 1.43;
    ${map(
      {
        mobile: '-16px',
        tablet: '-24px',
        desktop: '-48px',
      },
      (marginLeft) => `margin-left: ${marginLeft};`
    )};
    ${pl(3)};
    ${py(2)};
    background: url(${QuoteImage}) no-repeat;
    background-size: 40px;
    background-position: top 8px left 6px;
  }
`;

const Quote = ({ text }) => {
  return <Container><div>{text}</div></Container>;
};

export default Quote;
