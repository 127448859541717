import React from 'react';
import SvgIcon from 'utils/SvgIcon';

const External = ({ fillColor, hoverFill, style }) => (
  <SvgIcon
    {...{ fillColor, hoverFill, style }}
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50415 5.42086L7.58329 7.34171V1.16667H6.41663V7.34171L4.49577 5.42086L3.67081 6.24582L6.99996 9.57496L10.3291 6.24582L9.50415 5.42086ZM12.8333 11.6667V9.33334H11.6666V11.6667H2.33329V9.33334H1.16663V11.6667C1.16663 12.311 1.68896 12.8333 2.33329 12.8333H11.6666C12.311 12.8333 12.8333 12.311 12.8333 11.6667Z"
    />
  </SvgIcon>
);

export default External;
