import React from 'react';
import styled, { css } from 'styled-components';
import { map } from 'styled-components-breakpoint';
import { pb } from 'styled-components-spacing';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { GridWrapperStyles, ImageGroupStyles } from '../Partials';
import { Text } from 'themes/components';

const Container = styled.div`
  ${GridWrapperStyles};
  ${ImageGroupStyles};
`;

const ImageGridStyles = css`
  display: grid;
  grid-gap: 16px;
  grid-template-areas: 'full half-one' 'full half-two';
  & > div:first-of-type {
    grid-area: full;
  }
  & > div:nth-of-type(2n) {
    grid-area: half-one;
  }
  & > div:nth-of-type(3n) {
    grid-area: half-two;
  }
`;

const ImageSplitStyles = css`
  display: grid;
  grid-gap: 16px;
  ${map(
    {
      mobile: '1fr',
      tablet: '1fr 1fr'
    },
    (cols) => `grid-template-columns: ${cols};`
  )};
`;

const Images = styled.div`
  grid-column: 1/-1;
  ${pb(1)};
  ${(props) => (props.grouping === 'single' ? `` : '')}
  ${(props) => (props.grouping === 'split' ? ImageSplitStyles : '')}
  ${(props) => (props.grouping === 'grid' ? ImageGridStyles : '')}
`;

const getImageGrouping = (length) => {
  if (length <= 1) return 'single';
  if (length === 2) return 'split';
  if (length >= 3) return 'grid';
};

const ImageGroup = ({ caption, items }) => {
  const captionText = caption?.text;
  const images = items
    .map(({ images }) => images || null)
    .filter((image) => !!image);

  return (
    <Container {...{caption}}>
      <Images {...{ grouping: getImageGrouping(images.length) }}>
        {images.map((image) => (
          <GatsbyImage image={getImage(image)} alt={image?.alt || captionText} />
        ))}
      </Images>
      { captionText && <Text variant="small">{captionText}</Text> }
    </Container>
  );
};

export default ImageGroup;
