const getAuthor = (author) => {
  const {
    name,
    location,
    description,
    image,
    twitter,
    artstation,
    instagram,
    linkedin,
    website
  } = author?.document?.data || {};
  return {
    name: name?.text,
    description: description?.text,
    image: image,
    location: location?.text,
    website: website?.url,
    instagram: instagram?.url,
    twitter: twitter?.url,
    artstation: artstation?.url,
    linkedin: linkedin?.url
  };
};
export default getAuthor;
